export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  FACET_PANEL_CLASS: 'page-sidebar',
  MAIN_CONTENT_CLASS: 'page-content',
  PRODUCT_REPEATER_CLASS: 'productGrid product-grid-view',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  SEARCH_BOX_PLACEHOLDER: 'Search the store',
  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_FORM_CLASS: 'form-field',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'form-button',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Vehicle',

  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',
  GARAGE_TITLE: '',

  VEHICLE_CATEGORIES_CONTAINER_CLASS: 'container',
};
