import originalConfig from 'Stores/slt-performance/config.js';

function verityAndInit(config) {
  if (!config.Widgets) {
    throw new Error('Config must have at least one widget');
  }
  try {
    config.InitFunc?.();
  } catch (e) {
    console.error(e);
  }
}

// TODO: move this call right before setupPage() and place verityAndInit to _common.js
// reason: before switched to modules we assumed that we would load templates before global init
// and after global init we load components that which would use global config to setup themselve
// but global init in modular application is voilation of module concept and in order to get rid
// of this violation we would need to
// 1) get rid of model.*Config.js files and all reliance on them
// 2) replace componentlessWidgetsConfig.js -> widgetsConfig.js
// 3) move dom manipulation from widgetProcessor.js to all.tsx s(because
// we implicitly relying on verityAndInit being called before widgetProcessor)
// after that we would no longer break module isolation by global import and this would allow
// us to call this function after all imports because imports would be side effect free
verityAndInit(originalConfig);
