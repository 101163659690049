const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/categories';
const brandSelectionPageUrl = '/brands/';

const brandPageSelector = '[data-content-region="brand_below_header"]';
const categoryPageSelector = '[data-content-region="category_below_header"]';

globalThis.Convermax.handleFitmentTabClick = (e) => {
  const tabTitle = e.target.closest('.toggleLink');
  const tabContent = tabTitle?.parentElement?.parentElement?.querySelector('.toggle-content');

  if (tabTitle && tabContent) {
    tabTitle.classList.toggle('is-open');
    tabContent.classList.toggle('is-open');
  }
};

function InitFunc() {
  if (window.document.querySelector('#cm_results')) {
    const unwantedPageWrappers = [
      ['.page > .page-content.page-content--centered', 'page-content', 'page-content--centered'],
    ];

    unwantedPageWrappers.forEach(([elemSelector, ...classNames]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(...classNames);
    });
  }
}

const AfterInit = () => {
  const searchBoxButton = window.document.querySelector('.navUser-action.navUser-action--quickSearch');

  searchBoxButton?.addEventListener('click', () => {
    window.document.querySelector('#cm_SearchBox .cm_searchInput')?.focus();
  });
};

export default {
  platform: 'bigcommerce',
  InitFunc,
  AfterInit,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  product: {
    noImageSrc:
      'https://cdn11.bigcommerce.com/s-1hccpqeri3/stencil/3687e1c0-b928-013a-5bc3-2655e9005da0/img/noImage.jpg',
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.body .page',
      visibleIf: { selector: categoryPageSelector, ignoreUrls: [categorySelectionPageUrl] },
      template: 'SearchPage',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: '.body .page',
      visibleIf: { selector: brandPageSelector, ignoreUrls: [brandSelectionPageUrl] },
      template: 'SearchPage',
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: { insertBefore: '.body .page' },
      template: 'facetTiles/subtitles',
      facetField: 'category',
      visibleIf: { selector: categoryPageSelector, ignoreUrls: [categorySelectionPageUrl] },
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    { name: 'SearchResult' },

    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { replace: '#quickSearch .form' },
      template: 'searchBox/dialogButtonAdaptable',
      onSubmit: () => window.document.querySelector('.navUser-action--quickSearch.search-open')?.click(),
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: 'body.type-default .main ',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 780,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        firstChildOf: '.header-right .navUser-section',
        class: 'navUser-item cm_garage-container',
        wrapper: 'li',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: {
        firstChildOf: '.productView-details .productView-options',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: 'body.type-product .productView-description-inn',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: { replace: '.brandGrid' },
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      visibleIf: { selector: '[data-content-region="brands_below_header"]' },
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
