//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-864:_2698,_3232,_1080,_1088,_5173,_736,_4908,_2364;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-864')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-864', "_2698,_3232,_1080,_1088,_5173,_736,_4908,_2364");
        }
      }catch (ex) {
        console.error(ex);
      }